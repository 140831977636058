// is touch
function isTouchDevice() {
	return (("ontouchstart" in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
}

if (isTouchDevice()) {
	$("body").removeClass("des");
	$("body").addClass("mob");
} else {
	$("body").removeClass("mob");
	$("body").addClass("des");
}

const doc = document.documentElement
const firstScreenTeaser = document.querySelector('.first-screen__teaser');
const appSizes = () => {
	let windowHeight = $(window).height();
	doc.style.setProperty('--app-height', windowHeight + "px");

	if (firstScreenTeaser) {
		let firstScreenTeaserHeight = firstScreenTeaser.getBoundingClientRect().height;
		doc.style.setProperty('--first-screen-teaser-height', firstScreenTeaserHeight + "px");
	}
}
appSizes();


// Loaded
document.addEventListener('DOMContentLoaded', function () {
	appSizes();
});


// Resize
$(window).on("resize", function () {
	// is touch
	isTouchDevice();

	// app heights
	appSizes();

	// Main nav
	if ($(".article-first__menu-container").hasClass("active") && window.matchMedia("(min-width: 768px)").matches) {
		$(".article-first__menu-toggler").removeClass("active");
		$(".article-first__menu-container").removeClass("active");
	}

	// Article main nav
	if ($(".article-first__menu-container").hasClass("active") && window.matchMedia("(min-width: 768px)").matches) {
		$(".article-first__menu-toggler").removeClass("active");
		$(".article-first__menu-container").removeClass("active");
	}
});


// Scroll
let lastScrollTop = 0;
$(window).on("scroll", function () {
	// app heights
	appSizes();
})


// Document click
$(document).on("click", function (e) {
	// Header contacts toggler
	if ($(".first-screen__menu-container").hasClass("active") && !window.matchMedia("(min-width: 768px)").matches) {
		var callbackPopup = $(".first-screen__menu-toggler, .first-screen__menu-container");
		if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
			$(".first-screen__menu-toggler").removeClass("active");
			$(".first-screen__menu-container").removeClass("active");
		};
	}
	
	// Article header contacts toggler
	if ($(".article-first__menu-container").hasClass("active") && !window.matchMedia("(min-width: 768px)").matches) {
		var callbackPopup = $(".article-first__menu-toggler, .article-first__menu-container");
		if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
			$(".article-first__menu-toggler").removeClass("active");
			$(".article-first__menu-container").removeClass("active");
		};
	}



	// Article share
	if ($(".article__share-list").hasClass("active")) {
		var callbackPopup = $(".article__share-toggler, .article__share-list");
		if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
			$(".article__share-list").removeClass("active");
		};
	}
});


// popups
$(".popup__close, .popup__cancel").on("click", function (e) {
	$("body").removeClass("no-scroll");
	$(this).closest(".popup").removeClass("active");

	
	// popup YT
	if ($(this).closest(".popup").hasClass("popup-yt")) {
		$(".popup-yt__iframe").attr("src", "");
	}
});

$(".popup").on("click", function (e) {
	var callbackPopup = $(".popup__card")
	if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
		$(this).removeClass("active");
		$("body").removeClass("no-scroll");

		// popup YT
		if ($(this).hasClass("popup-yt")) {
			$(".popup-yt__iframe").attr("src", "");
		}
	};
});

$("[data-popup-open]").on("click", function (e) {
	e.preventDefault();
	let dataPopupName = $(this).attr("data-popup-open");
	let dataPopupClose = $(this).attr("data-popup-close");
	let popup = $(".popup[data-popup-name=" + dataPopupName + "]");

	if (dataPopupClose === "true") {
		$(this).closest(".popup").removeClass("active");
		popup.addClass("active");
	} else {
		$("body").addClass("no-scroll");
		popup.addClass("active");
	}

	
	// popup YT
	if (dataPopupName === "popup-yt") {
		$(".popup-yt__iframe").attr("src", $(this).attr("href"));
	}


	// popup request
	if (dataPopupName == 'popup-request') {
		$(".popup-request__open-from").val($(this).attr("data-popup-request"));
	}
});

document.addEventListener("keypress", function onEvent(e) {
	if (e.key === "Escape") {
		if ($(".popup").hasClass("active")) {
			e.preventDefault();

			// popup YT
			if ($(".popup-yt").hasClass("active")) {
				$(".popup-yt__iframe").attr("src", "");
			}

			$(".popup").removeClass("active");
			$("body").removeClass("no-scroll");
		}
	}
});


// Header contacts toggler
$(".first-screen__menu-toggler").on("click", function () {
	$(this).toggleClass("active");
	$(".first-screen__menu-container").toggleClass("active");
});


// Article Header contacts toggler
$(".article-first__menu-toggler").on("click", function () {
	$(this).toggleClass("active");
	$(".article-first__menu-container").toggleClass("active");
});


// Swipers
const team = document.querySelector('.team')
if (team) {
	let teamSlider = new Swiper(".team__slider", {
		modules: [Navigation],
		spaceBetween: 12,
		slidesPerView: "auto",
		slideToClickedSlide: true,
		breakpoints: {
			768: {
				slidesPerView: "auto",
				spaceBetween: 14,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 14,
			},
			1280: {
				slidesPerView: 4,
				spaceBetween: 16,
			},
		},
		navigation: {
			nextEl: '.team__nav-btn--next',
			prevEl: '.team__nav-btn--prev'
		}
	});
}

const blogSlider = document.querySelector('.blog-slider')
if (blogSlider) {
	let blogSwiper = new Swiper(".blog-slider__list-wrapper", {
		modules: [Navigation],
		spaceBetween: 12,
		slidesPerView: "auto",
		breakpoints: {
			768: {
				slidesPerView: "auto",
				spaceBetween: 14,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 14,
			},
			1280: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
		},
		navigation: {
			nextEl: '.blog-slider__nav-btn--next',
			prevEl: '.blog-slider__nav-btn--prev'
		}
	});
}


// Project teaser info additional
$(".project-teaser__info-additional-toggler").on("click", function () {
	$(".project-teaser__info-additional").slideToggle();
});


// Project gallery tabs
$(".project-gallery__nav-btn").on("click", function () {
	if (!$(this).hasClass('active')) {
		let index = $(this).index();

		$(".project-gallery__nav-btn").not(this).removeClass("active");
		$(".project-gallery-slider.active").removeClass("active");

		$(this).addClass("active");
		$(".project-gallery-slider").eq(index).addClass("active");

		console.log(index);
	}
	// $(".project-teaser__info-additional").slideToggle();
});


// Article share
$(".article__share-toggler").on("click", function () {
	$(".article__share-toggler").not(this).siblings(".article__share-list").removeClass("active");
	$(this).siblings(".article__share-list").toggleClass("active");
});

// Copy article url
$(".article__share-btn--copy").on("click", function () {
	let url = document.location.href;
	navigator.clipboard.writeText(url);
	$(this).siblings(".article__share-copied").addClass("active");
	setTimeout(() => {
		$(this).siblings(".article__share-copied").removeClass("active");
	}, 2000);
});
